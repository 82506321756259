<template>
  <!-- loader  -->
  <loader v-if="loader" />
  <!-- Start advertisements Section -->
  <div class="explore-section main-padding">
    <div class="container">
      <h2 class="section-title">بنراتي</h2>

      <section v-if="showtap1">
        <div class="">
          <div class="eplore-cards" v-if="activeAds.length > 0">
            <div class="explore-card" v-for="fav in activeAds" :key="fav.id">
              <div class="card-container">
                <router-link
                  :to="'single_banner_show/'+fav.id"
                ></router-link>
                <div class="explore-card-head">
                  <img :src="fav.image" alt="" />
                </div>
                <div class="explore-card-body" v-if="fav.details">
                  <p class="ads-city">{{ fav.details }}</p>
                </div>
                <div class="explore-card-body" v-else>لا يوجد وصف</div>
              </div>
            </div>
          </div>

          <div class="noDataFound" v-else>
            <v-alert color="info" icon="$info" title="لا توجد بنرات "></v-alert>
          </div>
        </div>

        <!-- pagination  -->
        <paginate
          v-model="currentPageP"
          :page-count="totalPagesP"
          :click-handler="(page) => pageClickHandler(page)"
          :prev-text="'السابق'"
          :next-text="'التالي'"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :no-li-surround="true"
          v-if="activeAds.length > 0"
        >
        </paginate>
      </section>
    </div>
    <addAdsPanner />
  </div>
  <!-- End advertisements Section -->
  <!-- Start advertisements Section -->
  <div class="explore-section main-padding">
    <div class="container"></div>
  </div>
  <!-- End advertisements Section -->
  <addAdsPanner />
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
import loader from "../components/Shared/pageLoader.vue";
import Paginate from "vuejs-paginate-next";
import addAdsPanner from "../components/ads/addAdsPanner";

export default defineComponent({
  name: "advertisementsView",
  data() {
    return {
      showtap1: true,
      showtap2: false,
      activeAds: [],
      finishedAds: [],
      loader: true,
      fd_img: [],

      // active pagination
      currentPageP: 1,
      perPageP: 10,
      totalPagesP: 0,

      // finished pagination
      currentPage: 1,
      perPage: 10,
      totalPages: 0,

      catsAdsIds: [],
      catsAdsIds2: [],
    };
  },
  created() {
    this.totalPagesP = Math.ceil(this.activeAds.length / this.perPageP);

    this.totalPages = Math.ceil(this.finishedAds.length / this.perPage);
  },
  methods: {
    async addHeart(adId) {
      const fd = new FormData();
      fd.append(`advertisement id`, adId);
      await axios
        .post(`favourite-advertisement`, fd, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.data.key == "success") {
            this.$swal({
              icon: "success",
              title: res.data.msg,
              timer: 2000,
              showConfirmButton: false,
            });

            setTimeout(() => {
              this.getActiveAds();
            }, 2000);
          } else {
            this.$swal({
              icon: "error",
              title: res.data.msg,
              timer: 2000,
              showConfirmButton: false,
            });
          }
        });
    },
    async addHeart2(adID) {
      const fd = new FormData();
      fd.append(`advertisement id`, adID);
      await axios
        .post(`favourite-advertisement`, fd, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.data.key == "success") {
            this.$swal({
              icon: "success",
              title: res.data.msg,
              timer: 2000,
              showConfirmButton: false,
            });

            setTimeout(() => {
              this.getFinishedAds();
            }, 2000);
          } else {
            this.$swal({
              icon: "error",
              title: res.data.msg,
              timer: 2000,
              showConfirmButton: false,
            });
          }
        });
    },
    showMe() {
      this.showtap1 = true;
      this.showtap2 = false;
    },
    showMe2() {
      this.showtap2 = true;
      this.showtap1 = false;
    },

    // get active ads
    async getActiveAds() {
      await axios
        .get("get-myBanners", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          // console.log('######################')
          // console.log(res.data.data);
          // console.log('######################')
          this.activeAds = res.data.data;
          this.loader = false;
          this.fd_img = res.data.data;

          for (let i = 0; i < this.fd_img.length; i++) {
            this.catsAdsIds.push(this.activeAds[i].id);
            localStorage.setItem("fd_img", this.fd_img[i]);
          }
        });
    },

    pageClickHandler(page) {
      this.currentPageP = page;
      this.getActiveAds();
    },

    pageClickHandle2(page) {
      this.currentPage = page;
      this.getFinishedAds();
    },
  },

  components: {
    loader,
    Paginate,
    addAdsPanner,
  },
  mounted() {
    this.getActiveAds();
  },
});
</script>
<style scoped>
a {
  text-decoration: none !important;
}
.noDataFound {
  width: 300px;
}
</style>
