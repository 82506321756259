<template>
  <div>
    <h1>Failed Payment</h1>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>

</style>