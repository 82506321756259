export default {
  "nav": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "cats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
    "favs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorites"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search here"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upload advertisement"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "rates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rates"])},
    "ads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advertisement"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Out"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Account"])}
  },
  "common": {
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more"])},
    "uploadAds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Panner"])},
    "sendRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["send request"])},
    "uploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upload image"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])},
    "sendSuc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request has been sent to the management , Your request will be answered "])}
  },
  "cats": {
    "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse Categories"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "browswAds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse Advertisement"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filter"])},
    "mainDep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["main department"])},
    "subDep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sub department"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["city"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])},
    "deliveryCompans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delivery companies"])}
  },
  "footer": {
    "mainLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Links"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "cats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Ads"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & Conditions"])},
    "followUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow Us"])},
    "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
    "insta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
    "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter"])},
    "youtube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youtube"])},
    "snap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snapchat"])},
    "nawaf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Rights Reserved To NAWAF Website"])},
    "awamer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design & programming for Awamer El-Shabka"])}
  },
  "contact": {
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["send"])},
    "messageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["message text"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
    "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["full name"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save"])},
    "confirmPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirm new Password"])},
    "enterConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter new Password again"])},
    "newPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new Password"])},
    "enterNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter new Password"])},
    "enterCurrent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter current password"])},
    "currentPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["current password"])},
    "changePassTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
    "validateName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your full name."])},
    "validatePhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your phone number."])},
    "validateEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email address."])},
    "validateMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your message."])}
  },
  "profile": {
    "changeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Your Information"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone"])},
    "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Changes"])},
    "changePass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])}
  },
  "validation": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["value is empty"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wrong in email format"])}
  },
  "auth": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "phone_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone number / email"])},
    "createAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["create account"])},
    "donotHaveAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do not have account ?"])},
    "forgetPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forget password?"])},
    "passwordPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter password"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])},
    "phonePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter phone number / email"])}
  }
}