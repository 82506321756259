<template>
  <!-- Img Upload Modal -->
  <div
    class="modal fade"
    v-show="adPanner"
    id="imgModal"
    aria-hidden="true"
    aria-labelledby="exampleModalToggleLabel2"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="content-model-me">
          <div class="modal-header">
            <h2 class="section-title">اضافة بانر اعلاني</h2>
          </div>

          <form ref="uploadForm" @submit.prevent="uploadAds()">
            <div class="modal-body">
              <div class="mb-3">
                <label for="exampleFormControlTextarea1" class="form-label">تفاصيل البانر
                  <span style="color: gray">*اختياري*</span>
                </label>
                <textarea
                  class="form-control"
                  name="details"
                  v-model="details"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  placeholder="اكتب هنا"
                ></textarea>
                <div>بامكانك الكتابة على الصورة او عدم الكتابة</div>
              </div>

              <div class="upload-img my-4">
                <label for="imgUpload" class="w-100 mb-1 text-center">اضف صورة البانر</label>
                <input
                  type="file"
                  name="image"
                  accept="image/*"
                  id="imgUpload"
                  class="hidden-input img-upload-input"
                  @change="uploadPanner"
                />
                <label for="imgUpload" class="label-img lg mx-auto">
                  <img v-if='image === null' :src="require('../../assets/imgs/photo-camera.png')" alt="Uploaded Image" />
                  <img v-else :src="image" alt="Uploaded Image" class="uploaded-img" />
                </label>
                <div class="img-upload-show"></div>
              </div>
            </div>

            <div class="modal-footer">
              <button class="main-btn md up" :disabled="disabled">
                اضف البانر
                <div class="spinner-border" role="status" v-if="disabled">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- done modal  -->
  <v-dialog v-model="dialog" width="auto" persistent>
    <v-card>
      <div class="d-flex justify-content-end w-100">
        <button
          type="button"
          class="close-model-btn"
          style="font-size: 22px"
          @click="dialog = false"
        >
          <i class="fa-regular fa-circle-xmark"></i>
        </button>
      </div>

      <div class="modal-header">
        <h2 class="section-title">{{ $t("common.sendSuc") }}</h2>
      </div>

      <div class="modal-body">
        <img :src="done" alt="Success" class="done-img" />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      image:null,
      done: require("../../assets/imgs/done.gif"),
      widthAll: false,
      details: "",
      adPanner: false,
      dialog: false,
      disabled: false,
    };
  },
  mounted() {
    this.adPanner = true;
  },
  methods: {
    uploadPanner(e) {
      const file = e.target.files[0];
      
      if (file) {
        const reader = new FileReader();
        
        reader.onload = (event) => {
          this.image = event.target.result; // Set the base64 image for display
          window.localStorage.setItem('banner_img' , event.target.result)
          this.widthAll = true; // Update widthAll to true
        };
        
        reader.readAsDataURL(file); // Convert file to base64 string
      } else {
        // Reset to default image if no file is selected
        this.image = require("../../assets/imgs/photo-camera.png");
        this.widthAll = false; // Optionally reset widthAll
      }
    },

    async uploadAds() {
      this.disabled = true; // Disable the button
      try {
        // Store the banner image and details in localStorage
        window.localStorage.setItem('bannerOrAd', 'banner');
        window.localStorage.setItem('banner_text', this.details);
        document.querySelectorAll(".modal-backdrop").forEach((element) => {
          element.classList.remove("show");
          element.style.display = "none";
        });
        // Optionally check if an image is uploaded
        if (this.image === require("../../assets/imgs/photo-camera.png")) {
          this.$swal({
            icon: "error",
            title: 'يجب إضافة صورة البانر',
            timer: 2000,
            showConfirmButton: false,
          });
        } else {
          this.$swal({
            icon: "success",
            title: 'تم إضافة الصورة بنجاح',
            timer: 1000,
            showConfirmButton: false,
          });
          setTimeout(() => {
            this.$router.push("/bannerPayment");
          }, 800);
        }
        // Show the success dialog
        this.dialog = true;
        setTimeout(() => {
          this.dialog = false; // Hide the dialog after a delay
        }, 2000);
      } catch (error) {
        console.error("Error uploading ads:", error);
      } finally {
        this.disabled = false; // Re-enable the button
      }
    },
  },
};
</script>

<style>
.upload-img .label-img.lg .uploaded-img{
  max-width: unset;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
